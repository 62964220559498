<template>
  <div>
    <b-modal
      id="image-cropper-modal"
      ok-title="Crop"
      size="lg"
      @ok="crop"
    >
      <cropper
        ref="cropper"
        class="upload-example-cropper"
        :stencil-props="stencilProps"
        :src="imageBlob"
      />
    </b-modal>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'ImageCropper',
  components: {
    Cropper,
  },
  props: {
    image: { type: File, default: null },
    stencilProps: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      imageBlob: '',
    }
  },
  watch: {
    image() {
      this.loadImage()
    },
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.imageBlob) {
      URL.revokeObjectURL(this.imageBlob)
    }
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      canvas.toBlob(blob => {
        this.$emit('update:image', new File([blob], 'cropped_image', { type: this.image.type }))
      }, this.image.type)
    },
    loadImage() {
      // Ensure that you have a file before attempting to read it
      if (this.image) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        URL.revokeObjectURL(this.image)
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(this.image)

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        this.imageBlob = blob
      }
    },
  },
}
</script>

<style>

</style>

<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group
      v-if="!$attrs.disabled"
      :label-for="id"
    >
      <template #label>
        <span
          class="btn btn-outline-primary"
        >
          <feather-icon icon="UploadIcon" />
          {{ label }}
        </span>

        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        />
      </template>

      <image-cropper
        v-if="cropper"
        :image.sync="croppedImageProxy"
        :stencil-props="stencilProps"
      />

      <b-file
        v-show="false"
        :id="id"
        v-model="filesProxy"
        :accept="accept"
        placeholder=""
        :state="getValidationState(validationContext)"
        :multiple="$attrs.multiple"
      />

      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>

    </b-form-group>

    <slot
      v-if="(validationContext.validated&&validationContext.valid)||
        (!validationContext.validated&&validationContext.valid)"
      name="preview"
    />
  </validation-provider>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import ImageCropper from './ImageCropper.vue'

export default {
  name: 'UploadFiles',
  components: { ImageCropper },
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    accept: { type: String, default: '' },
    rules: { type: [String, Object], default: '' },
    cropper: { type: Boolean, default: false },
    stencilProps: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      files: [],
    }
  },
  computed: {
    croppedImageProxy: {
      get() {
        return this.files?.[0]
      },
      set(value) {
        this.$emit('update:files', [value])
      },
    },
    filesProxy: {
      get() {
        return this.files
      },
      set(files) {
        const filesArray = Array.isArray(files) ? files : [files].filter(file => file)
        this.files = filesArray
        if (this.cropper) {
          this.showCropper()
        } else {
          this.$emit('update:files', this.files)
        }
      },
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
  methods: {
    showCropper() {
      if (this.cropper) { this.$bvModal.show('image-cropper-modal') }
    },
  },
}
</script>
<style lang="scss">
.link{
  color: #109CF1;
  cursor: pointer;
  font-size: 1.15em;
  font-weight: 500;
}
</style>

<template>
  <div class="mb-1">
    <flicking
      :options="{ align: 'prev', circular: true }"
    >
      <div
        v-for="(image,index) in filteredImages"
        :key="index"
        class="flicking-panel image-container"
      >
        <feather-icon
          v-if="!$attrs.disabled"
          class="x-icon"
          icon="XCircleIcon"
          size="23"
          @click="removeImage(index,image.id)"
        />

        <b-img
          :src="convertFileToBlob(image)"
          class="ml-1 pointer"
          rounded
          height="250em"
          @click="showImagesPreview(index)"
        />
      </div>
    </flicking>

    <vue-easy-lightbox
      move-disabled
      :visible="visible"
      :imgs="blobs"
      :index="imageIndex"
      @hide="handleHide"
    />
  </div>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import { Flicking } from '@egjs/vue-flicking'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'ImagesPreview',
  components: {
    Flicking,
    VueEasyLightbox,
  },
  props: {
    images: { type: Array, default: () => [] },
  },
  data() {
    return {
      visible: false,
      blobs: [],
      imageIndex: 0,
    }
  },
  computed: {
    filteredImages() {
      const regex = /(jpg|jpeg|png|svg)/
      return this.images.filter(image => regex.test(image) || regex.test(image?.type))
    },
  },
  watch: {
    images(newVal) {
      this.convertImagesToBlobs(newVal)
    },
  },
  setup() {
    const { confirmOperation } = handleAlerts()
    return {
      confirmOperation,
    }
  },
  mounted() {
    this.convertImagesToBlobs(this.images)
  },
  destroyed() {
    this.clearBlobs()
  },
  methods: {
    showImagesPreview(index) {
      this.imageIndex = index
      this.visible = true
    },
    convertImagesToBlobs(images) {
      images.forEach((ele, index) => {
        this.$set(this.blobs, index, this.convertFileToBlob(ele))
      })
    },
    convertFileToBlob(file) {
      return file instanceof File ? URL.createObjectURL(file) : file
    },
    removeImage(index, id) {
      this.confirmOperation().then(() => {
        this.$emit('delete', id)
        this.$delete(this.images, index)
      })
    },
    clearBlobs() {
      this.blobs.forEach(image => {
        URL.revokeObjectURL(image)
      })
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>
<style lang="scss">
</style>
<style lang="scss">
@import '~@egjs/vue-flicking/dist/flicking.css';

.image-container {
  position: relative;
}

.x-icon {
  position: absolute;
  top:5px;
  right:4px;
  color: rgb(235, 235, 235);
  background-color: rgba(105, 105, 105, 0.604);
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.uploaded-image {
  height:15em;
  width: 100%;
  border: 1px solid rgba(150, 150, 150, 0.303);
  padding:.7em
}
.pointer {
  cursor:pointer
}
</style>

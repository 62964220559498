<template>
  <validation-provider
    ref="textInput"
    #default="validationContext"
    :name="name"
    :rules="rules"
    :vid="id"
  >
    <b-form-group :label-for="id">
      <template #label>
        <span :class="{ required: JSON.stringify(rules).includes('required') }">
          {{ name }}
        </span>
      </template>
      <b-input-group
        :prepend="prependIcon"
        :append="appendIcon"
      >
        <b-form-input
          :id="id"
          v-model="tempText"
          :placeholder="placeholder || name"
          :state="validate(validationContext)"
          :class="$attrs['input-class']"
          :type="type"
          :disabled="disabled"
        />
      </b-input-group>
      <b-form-invalid-feedback :state="validate(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>

export default {
  name: 'TextInput',
  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    text: { type: [String, Number], default: '' },
    rules: { type: [String, Object], default: '' },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
  },
  computed: {
    tempText: {
      get() {
        return this.text
      },
      set(value) {
        this.$emit('update:text', value)
      },
    },
  },
  methods: {
    validate(validationContext) {
      return validationContext.dirty || validationContext.validated ? validationContext.valid : null
    },
  },
}
</script>
<style lang="">
</style>

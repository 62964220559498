<template>
  <b-button
    ref="submit_button"
    variant="primary"
    type="submit"
    :class="btnClass"
    @click="disableButtonUntilResolving"
  >
    {{ submitText }}
  </b-button>
</template>
<script>
export default {
  name: 'SubmitButton',
  props: {
    submit: { type: Function, default: () => {} },
    submitText: { type: String, default: 'Save' },
    handleSubmit: { type: Function, default: () => {} },
    btnClass: { type: String, default: '' },
  },
  methods: {
    disableButtonUntilResolving() {
      this.handleSubmit(() => {
        this.disableSubmit()

        this.submit().catch(() => {
          this.enableSubmit()
        })
      })
    },
    disableSubmit() {
      this.$refs.submit_button.disabled = true
    },
    enableSubmit() {
      this.$refs.submit_button.disabled = false
    },
  },
}
</script>
<style lang="scss">

</style>
